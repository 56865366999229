import axios from 'axios';
import { BASE_URL } from '../../Constants/constant.js';
const token = localStorage.getItem('token');
export const getApi = async (url, config) => {
  try {
    const response = await axios.get(`${BASE_URL}/${url}`,{
      'Authorization': `Bearer ${token}`,
    }, config);
    return response;
  } catch (error) {
    return error.response || error;
  }
};

