import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchRequest, fetchSuccess, fetchFailure, clearResults } from '../slices/SearchSlice.js';
import { getApi } from '../Apirequest/searchApiRequest.js';

function* fetchDataSaga(action) {
  const { searchTerm } = action.payload;
  if (!searchTerm.trim()) {
    yield put(clearResults());
    return;
  }

  try {
    const token = localStorage.getItem('token');
    const headers = token ? { Authorization: `Bearer ${token}` } : {};

    const response = yield call(getApi, `api/v-1/search/get`, {
      params: { q: searchTerm },
      headers: headers,
    });

    if (response.status === 200) {
      yield put(fetchSuccess({ results: response.data.results.user }));
    } else {
      yield put(fetchFailure(response.data || 'An error occurred'));
    }
  } catch (error) {
    yield put(fetchFailure(error.message || 'An error occurred'));
  }
}

function* watchSearchSaga() {
  yield takeLatest(fetchRequest.type, fetchDataSaga);
}

export default watchSearchSaga;
